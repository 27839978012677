import _safeBuffer from "safe-buffer";
import _stream from "stream";
import _string_decoder from "string_decoder";
import _inherits from "inherits";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _safeBuffer.Buffer;
var Transform = _stream.Transform;
var StringDecoder = _string_decoder.StringDecoder;
var inherits = _inherits;

function CipherBase(hashMode) {
  Transform.call(this || _global);
  (this || _global).hashMode = typeof hashMode === "string";

  if ((this || _global).hashMode) {
    (this || _global)[hashMode] = (this || _global)._finalOrDigest;
  } else {
    (this || _global).final = (this || _global)._finalOrDigest;
  }

  if ((this || _global)._final) {
    (this || _global).__final = (this || _global)._final;
    (this || _global)._final = null;
  }

  (this || _global)._decoder = null;
  (this || _global)._encoding = null;
}

inherits(CipherBase, Transform);

CipherBase.prototype.update = function (data, inputEnc, outputEnc) {
  if (typeof data === "string") {
    data = Buffer.from(data, inputEnc);
  }

  var outData = this._update(data);

  if ((this || _global).hashMode) return this || _global;

  if (outputEnc) {
    outData = this._toString(outData, outputEnc);
  }

  return outData;
};

CipherBase.prototype.setAutoPadding = function () {};

CipherBase.prototype.getAuthTag = function () {
  throw new Error("trying to get auth tag in unsupported state");
};

CipherBase.prototype.setAuthTag = function () {
  throw new Error("trying to set auth tag in unsupported state");
};

CipherBase.prototype.setAAD = function () {
  throw new Error("trying to set aad in unsupported state");
};

CipherBase.prototype._transform = function (data, _, next) {
  var err;

  try {
    if ((this || _global).hashMode) {
      this._update(data);
    } else {
      this.push(this._update(data));
    }
  } catch (e) {
    err = e;
  } finally {
    next(err);
  }
};

CipherBase.prototype._flush = function (done) {
  var err;

  try {
    this.push(this.__final());
  } catch (e) {
    err = e;
  }

  done(err);
};

CipherBase.prototype._finalOrDigest = function (outputEnc) {
  var outData = this.__final() || Buffer.alloc(0);

  if (outputEnc) {
    outData = this._toString(outData, outputEnc, true);
  }

  return outData;
};

CipherBase.prototype._toString = function (value, enc, fin) {
  if (!(this || _global)._decoder) {
    (this || _global)._decoder = new StringDecoder(enc);
    (this || _global)._encoding = enc;
  }

  if ((this || _global)._encoding !== enc) throw new Error("can't switch encodings");

  var out = (this || _global)._decoder.write(value);

  if (fin) {
    out += (this || _global)._decoder.end();
  }

  return out;
};

exports = CipherBase;
export default exports;